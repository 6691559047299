// NB: to build a cordova app, relative path is needed here
var _require = require('../../src/pages/pagesKeys'),
  MOBIGEO_PAGE_KEY = _require.MOBIGEO_PAGE_KEY;
var projectConfig = require('../../../legacy-app-controller/config');
var _require2 = require('./screenSaverConfig'),
  IMAGE_MODE = _require2.IMAGE_MODE;
var dataConfig = require('./dataConfig');

// Only export a default object, to be able to
// override properties depending on target environment

module.exports = {
  // Misc UI
  DELAY_BEFORE_CLICK_ACTION: 50,
  // ms
  SHOW_TERMS_OF_USE: true,
  // Terms of use URL
  TOU_LINK_TARGET: '_blank',
  TOU_LINK_OPENININAPPBROWSER: true,
  // App profiles
  SUPPORTED_PROFILES: ['default'],
  DEFAULT_PROFILE: 'default',
  // Routing (in two columns mode, = default page on left column)
  getHomePage: function getHomePage(profile) {
    return {
      pageKey: MOBIGEO_PAGE_KEY,
      props: null
    };
  },
  APP_TITLE_SEPARATOR: ' - ',
  ROUTE_SEPARATOR: '?',
  // Lang
  SUPPORTED_LANGS: ['fr', 'en', 'zh'],
  DEFAULT_LANG: 'fr',
  // Backend / Updates
  UPDATE_ENABLED: true,
  // Mobile-Spot link
  MOBILESPOT_WWW: 'http://www.mobile-spot.com',
  // SHARE:
  DEFAULT_SHARE_IMAGE: '',
  //  on LinkedIn
  LINKEDIN_API_KEY: '',
  //  on Facebook
  FACEBOOK_APP_ID: '',
  WEB_URL: "https://".concat(projectConfig.web.url),
  // In web mode, this fallback url can be used if the user's
  // browser does not meet some requirements (eg IE<11)
  WEB_FALLBACK: '',
  // e.g com.printemps.haussmann.dev
  NATIVE_ID: projectConfig.native.id,
  // Note: handling urls depending on environment is welcomed.
  // in that case, declare this variable in config-[env].js
  ADDITIONAL_DEFAULTSRC_CSP: [],
  /**
   * FEATURES
   */

  DOWNLOAD_FAVORITES: {
    FEATURE_ENABLED: false
  },
  APPOINTMENT_REQUEST: {
    FEATURE_ENABLED: false
    // webservice url is defined per environment
  },

  CONTRIBUTIONS_FEED: {
    FEATURE_ENABLED: false
  },
  FLIGHTS_SCHEDULE: {
    FEATURE_ENABLED: false
  },
  LOGIN: {
    FEATURE_ENABLED: false,
    // webservice url is defined per environment
    forgottenPasswordURL: null,
    forgottenPasswordURLTarget: '_blank',
    createAccountURL: null,
    createAccountURLTarget: '_blank',
    tosURL: null,
    tosURLTarget: '_blank'
  },
  POLL: {
    FEATURE_ENABLED: false
  },
  USER_DATA: {
    FEATURE_ENABLED: false
  },
  NETWORKING: {
    FEATURE_ENABLED: false
  },
  SHARE: {
    FEATURE_ENABLED: true,
    POSITION_SHARE_IMAGE: '',
    // Declare restrictions
    // If current page + current profile are found below,
    // then share button is hidden.
    RESTRICTIONS: [
      // e.g
      // { pageKey: EXHIBITOR_PAGE_KEY,
      //   for : [ VISITOR_GP_PROFILE ]
      // }
    ]
  },
  IMAGE_UPLOAD: {
    // see https://github.com/blueimp/JavaScript-Load-Image#options
    LOAD_IMAGE_OPTIONS: {
      maxWidth: 900,
      maxHeight: 900,
      contain: false,
      orientation: true,
      canvas: true
    },
    INPUT_ACCEPTED_MIME: 'image/*',
    OUTPUT_MIME: 'image/jpeg',
    OUTPUT_COMPRESSION: 0.92,
    DISPLAY_IMAGE_OPTIONS: {
      maxWidth: 300,
      maxHeight: 300,
      cropMinWidth: 100,
      cropMinHeight: 100,
      cropInitialWidth: 300,
      cropRatio: 0.85
    }
  },
  NOTES: {
    FEATURE_ENABLED: false
  },
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    // webservice url is defined per environment

    // Debouncing value represents the delay between
    // last favorite toggle (add or remove) and server call
    DEBOUNCING: 10 * 1000 // ms
  },

  SOCIAL: {
    FEATURE_ENABLED: false
  },
  EXPORT_EVENT: {
    FEATURE_ENABLED: false
  },
  MODAL_PRIVACY_AGREEMENT: {
    ENABLED_PLATFORM_FEATURE: []
  },
  MAP: {
    DEFAULT_MAP_PAGE_KEY: MOBIGEO_PAGE_KEY,
    // default map page key, when there is only one to be choosed like for the feature TwoColumn ( GOOGLE_MAP_PAGE_KEY OR MOBIGEO_PAGE_KEY )
    MOBIGEO: {
      FEATURE_ENABLED: true,
      API_KEY: 'ahd5Ahqu2oof',
      // need to be the same as in app-customs/config.default.js => data.default
      LOG_LEVEL: 'NONE',
      // accepted values: NONE | INFO | DEBUG
      USED_TABLES: [
      // DATA_TYPE_AIRCRAFTS,
      dataConfig.DATA_TYPE_BRANDS,
      // DATA_TYPE_EVENTS,
      // DATA_TYPE_EXHIBITORS,
      dataConfig.DATA_TYPE_ICONS, dataConfig.DATA_TYPE_PLACES, dataConfig.DATA_TYPE_RESTAURANTS, dataConfig.DATA_TYPE_MONUMENTS, dataConfig.DATA_TYPE_HAPPENINGS, dataConfig.DATA_TYPE_SERVICES
      // also: gtw_paths, paths, polygons, but they are in graph.json
      // which is an asset, updated by complete overriding.
      ],

      AUTO_RELOAD_AT_UPDATE: true
    },
    GOOGLE_MAP: {
      FEATURE_ENABLED: false
    }
  },
  PUSHWOOSH: {
    FEATURE_ENABLED: true
  },
  LOTTIE_SPLASHSCREEN: {
    FEATURE_ENABLED: true
  },
  GEOLOCATION: {
    FEATURE_ENABLED: true
  },
  iosAppStoreUrl: projectConfig.native.iosAppStoreUrl,
  playStoreUrl: projectConfig.native.playStoreUrl,
  name: projectConfig.native.name.default,
  author: projectConfig.native.author,
  SCREEN_SAVER: {
    imageMode: IMAGE_MODE.ANIMATION // "animation" | "static" | "onMove"
  }
};