// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, Provider } from 'react-redux';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import layoutCandidates from './layoutCandidatesZH';
// config
import { DATA_TYPE_PLACES } from 'app-customs/config/dataConfig';
import { getCurrent as getCurrentLang } from 'src/core/Lang';
// app modules
import TypeBar from 'src/components/type-bar/TypeBar';
import List from 'src/components/list/List';
import NoResult from 'src/components/no-result/NoResult';

import { getItemTogglePMR } from 'app-customs/config/menuConfig';

// import { TYPES } from 'src/core/util/ConfigItemFactory';
import TogglePMRItem from 'src/components/menu/TogglePMRItem';

import * as actions from 'src/store/actions';
import SearchPlaceBox from './SearchPlaceBox';
import SearchPlaceTypes from './SearchPlaceTypes';

import '../listDialog.scss';
import './SearchPlace.scss';

const LOG_PREF = '[SearchPlace] ';

export const COMPONENT_KEY = 'SearchPlace';
export const CONTAINER_DOM_ID = 'search-place--container';

let store;
export function setReduxStore(_store) {
  store = _store;
}

/**
 * TypeBar is showed closed when items count is higher than the value defined here
 * @type {Number}
 */
const CLOSED_IF_COUNT_ABOVE = 5;

const CLOSING_CLASS_LIST = ['list-dialog-content'];

const getTypedLists = (
  items,
  searchType,
  searchedString,
  actions,
  labels,
  isPending,
  selectedEntries,
  closeSearch
) => {
  const content = [];

  if (items) {
    Object.keys(items).forEach((dataType) => {
      // Ignore this dataType if it has no entry
      if (items[dataType].length === 0) {
        return;
      }

      // Prepare a 'places' array to display
      const placesItems = [];
      items[dataType].forEach((item) => {
        item.references.places.forEach((place) => {
          if (place) {
            // to add the info field to the placeItems
            const placeRef = item.places.find((placeObj) => placeObj.place_id === place.id) || {};

            placesItems.push({
              ...place,
              memberType: dataType,
              memberId: item.id,
              memberOriginalId: item.original_id,
              memberTitle: item.title,
              ...placeRef,
            });
          }
        });
      });

      // Ignore if there is no place to display
      if (placesItems.length === 0) {
        return;
      }

      const listProps = {
        items: placesItems,
        dataType: DATA_TYPE_PLACES,
        actions,
        labels,
        isPending,
        highlight: searchedString,
        searchType,
        displayFavorites: false,
        onSearchedItemClick: () => {
          closeSearch();
        },
      };
      const count = listProps.items ? listProps.items.length : 0;

      content.push(
        <TypeBar
          key={dataType}
          label={labels.data[dataType][count > 1 ? 'plural' : 'singular']}
          count={count}
          isClosed={false && count > CLOSED_IF_COUNT_ABOVE}
        >
          <Provider store={store}>
            <List {...listProps} />
          </Provider>
        </TypeBar>
      );
    });
  }

  return content.length > 0 ? content : <NoResult labels={labels} />;
};

const convertEntryForMap = (entry) =>
  !entry
    ? null
    : {
        id: entry.originalId || entry.id,
        placeId: entry.placeId,
        type: entry.type,
      };

const getBottomButtons = (searchType, entries, labels, actions, isPMREnabled) => {
  if (!searchType) {
    return;
  }

  const buttons = [];
  switch (searchType) {
    case SearchPlaceTypes.start:
      if (entries.start && entries.start.type) {
        // Show button to select destination
        buttons.push(
          <div
            key="go-dest"
            className="btn-choose-dest"
            onClick={() => {
              actions.showSearchPlaceDialog(SearchPlaceTypes.dest);
            }}
          >
            {labels.placeSearch.setDestination}
          </div>
        );
      }
      break;

    case SearchPlaceTypes.dest:
      // Show button to edit start
      buttons.push(
        <div
          key="go-start"
          className="btn-edit-start"
          onClick={() => {
            actions.showSearchPlaceDialog(SearchPlaceTypes.start);
          }}
        >
          {labels.placeSearch.editStart}
        </div>
      );
      if (/* entries.start && entries.start.placeId && */ entries.dest && entries.dest.placeId) {
        // Show button to compute itinerary
        buttons.push(
          <div
            key="submit"
            className="btn-compute-route"
            onClick={() => {
              actions.hideSearchPlaceDialog();
            }}
          >
            {labels.placeSearch.itinerary}
          </div>
        );
      }
      break;

    case SearchPlaceTypes.poi:
      // No button. When user selects a POI, it is directly displayed on map
      break;

    default:
      console.error(`${LOG_PREF}Invalid search type: ${searchType}`);
  }
  return buttons;
};

/**
 * Display a dialog (modal) allowing user to search for displayable POI
 * @param  {object} props
 */
class SearchPlace extends React.PureComponent {
  constructor(props) {
    super(props);

    this.keyboard = React.createRef();
    this.wrapperRef = React.createRef();
    this.state = {
      currentLang: getCurrentLang() || 'fr',
      keyboardHeight: 230,
      keyboardInput: props.searched,
      layoutKeyboard: {
        fr: {
          shift: [
            '@ # & é " \' è _ ç à + {bksp}',
            'A Z E R T Y U I O P € [ ]',
            'Q S D F G H J K L M ù * {enter}',
            'W X C V B N , ; : !',
            '{num} {space}',
          ],
          default: [
            '1 2 3 4 5 6 7 8 9 0 ( ) - = {bksp}',
            'A Z E R T Y U I O P { }',
            'Q S D F G H J K L M % {enter}',
            'W X C V B N ? . /',
            '{sym} {space}',
          ],
          langSwift: ['{FR} {EN} {ZH} {arrowleft}'],
        },
        zh: {
          shift: [
            '! @ # $ % ^ & * ) ( _ + {bksp}',
            'q w e r t y u i o p [ ]',
            "a s d f g h j k l ; ' {enter}",
            'z x c v b n m . - /',
            '{num} {space}',
          ],
          default: [
            '1 2 3 4 5 6 7 8 9 0 - = {bksp}',
            'q w e r t y u i o p { }',
            'a s d f g h j k l : " {enter}',
            'z x c v b n m ?',
            '{sym} {space}',
          ],
          langSwift: ['{FR} {EN} {ZH} {arrowleft}'],
        },
        en: {
          shift: [
            '! @ # $ % & * ( ) _ + {bksp}',
            'Q W E R T Y U I O P [ ]',
            "A S D F G H J K L ; ' {enter}",
            'Z X C V B N M , . /',
            '{num} {space}',
          ],
          default: [
            '1 2 3 4 5 6 7 8 9 0 - = {bksp}',
            'Q W E R T Y U I O P { }',
            'A S D F G H J K L : " {enter}',
            'Z X C V B N M ?',
            '{sym} {space}',
          ],
          langSwift: ['{FR} {EN} {ZH} {arrowleft}'],
        },
      },
    };

    this.setSearchField = this.setSearchField.bind(this);
    this.focusOnSearchField = this.focusOnSearchField.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
  }

  setSearchField(ref) {
    this.searchField = ref;
  }

  focusOnSearchField() {
    if (this.searchField) {
      this.searchField.focus();
    }
  }

  closeSearch(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.searchField && this.searchField.clear();
    this.searchField && this.searchField.blur();
    this.setState({
      keyboardInput: '',
    });
    this.props.actions.toggleCustomKeyboard(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentLang !== getCurrentLang()) {
      this.setState({
        currentLang: getCurrentLang(),
      });
    }
    if (!prevProps.isOpen && this.props.isOpen) {
      // autofocus on search field
      window.setTimeout(this.focusOnSearchField, 500);
    }
    if(prevProps.customKeyboardIsOpen !== this.props.customKeyboardIsOpen && !this.props.customKeyboardIsOpen){
      this.closeSearch();
    }
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (!this.wrapperRef.current.contains(event.target) || event.target.classList.contains(CLOSING_CLASS_LIST)) {
      this.closeSearch();
    }
  }

  onKeyPress(button) {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === '{num}' || button === '{sym}' || button === '{lock}') this.handleShift();
    else if (button === '{lang}') this.handleLangSwift();
    else if (button === '{FR}' || button === '{EN}' || button === '{ZH}') this.changeLang(button);
    else if (button === '{arrowleft}') this.backToDefaultLayout();
  }

  onChange = (input) => {
    this.setState({ keyboardInput: input });
  };

  handleShift() {
    let currentLayout = this.keyboard.current.options.layoutName;
    let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

    this.keyboard.current.setOptions({
      layoutName: shiftToggle,
    });
  }

  backToDefaultLayout() {
    this.keyboard.current.setOptions({
      layoutName: 'default',
    });
  }

  changeLang(lang) {
    this.setState({
      currentLang:
        lang === '{FR}' ? 'fr' : lang === '{EN}' ? 'en' : lang === '{ZH}' ? 'zh' : getCurrentLang(),
    });
    this.keyboard.current.setOptions({
      layoutName: 'default',
    });
  }

  handleLangSwift() {
    let currentLayout = this.keyboard.current.options.layoutName;
    let shiftToggle = currentLayout === 'langSwift' ? 'default' : 'langSwift';

    this.keyboard.current.setOptions({
      layoutName: shiftToggle,
    });
  }

  getStyleSearchContainer() {
    return this.props.customKeyboardIsOpen
      ? {
          // on open keyboard
          zIndex: 101,
        }
      : {
          backgroundColor: 'white',
          // on close keyboard
        };
  }

  render() {
    let entries;
    if (this.props.selectedEntries && typeof this.props.selectedEntries.toJS === 'function') {
      entries = this.props.selectedEntries.toJS();
    } else {
      entries = {};
    }

    const pmrToggle = (
      <TogglePMRItem
        label={this.props.labels.menu.pmrQuestion2}
        entry={getItemTogglePMR(this.props.labels)}
      />
    );
    return (
      <div
        id="search-place-container"
        className="list-dialog content-font dialog-with-inputs dialog-min-width"
        data-instructions={
          !this.props.searched
            ? this.props.customKeyboardIsOpen
              ? this.props.labels.search.instructions
              : ''
            : ''
        }
        style={{ ...this.getStyleSearchContainer() }}
      >
        {this.props.customKeyboardIsOpen && (
          <span
            className="fal fa-times fa-3x btn-close-keyboard"
            onPointerDown={this.closeSearch}
          ></span>
        )}
        <div id="sp-inner">
          <div
            style={{
              transition: 'bottom 0.2s',
              bottom: this.props.customKeyboardIsOpen ? '30px' : -this.state.keyboardHeight + 30,
            }}
            className={`keyboardContainer`}
            ref={this.wrapperRef}
          >
            {this.props.isOpen && typeof this.props.searched === 'string' && (
              <div className="list-dialog-content">
                {getTypedLists(
                  this.props.results,
                  this.props.resultsType,
                  this.props.searched,
                  this.props.actions,
                  this.props.labels,
                  this.props.isPending,
                  entries,
                  this.closeSearch
                )}
              </div>
            )}
            {this.props.searchType == SearchPlaceTypes.dest &&
              entries.dest &&
              entries.dest.placeId && <Provider store={store}>{pmrToggle}</Provider>}
            <div className={['sp-buttons-container', this.props.searchType].join(' ')}>
              {getBottomButtons(
                this.props.searchType,
                entries,
                this.props.labels,
                this.props.actions,
                this.props.isPMREnabled
              )}
            </div>
            {this.props.searchType && (
              <SearchPlaceBox
                searchFieldRef={this.setSearchField}
                type={this.props.searchType}
                selectedEntry={entries[this.props.searchType]}
                actions={this.props.actions}
                labels={this.props.labels}
                searchPlacePlaceholder={this.props.searchPlacePlaceholder}
                userIsLocated={this.props.userIsLocated}
                keyboardInput={this.state.keyboardInput}
                toggleCustomKeyboard={(value) =>
                  this.props.actions.toggleCustomKeyboard(value)
                }
                setKeyboardInput={(input) => {
                  this.keyboard.current.setInput(input);
                }}
              />
            )}
            <Keyboard
              mergeDisplay
              display={{
                '{FR}': 'FR',
                '{EN}': 'EN',
                '{ZH}': 'ZH',
                '{lang}': ' ',
                '{sym}': '#&@',
                '{num}': '1 2 3',
                '{bksp}': this.props.labels.keyboard.bksp,
                '{enter}': this.props.labels.keyboard.enter,
              }}
              layout={this.state.layoutKeyboard[this.state.currentLang]}
              buttonTheme={[
                {
                  class: 'lang fal fa-globe',
                  buttons: '{lang}',
                },
                {
                  class: 'small-btn',
                  buttons: '{sym} {num}',
                },
                { // here for zh, illusion of caplock, cause method pinyin work only with lowercase
                  class: 'capitalize',
                  buttons: 'a b c d e f g h i j k l m n o p q r s t u v w x y z',
                },
              ]}
              onInit={(keyboard) =>
                this.setState({ keyboardHeight: keyboard.keyboardDOM.clientHeight })
              }
              keyboardRef={(ref) => (this.keyboard.current = ref)}
              onKeyPress={(button) => this.onKeyPress(button)}
              onChange={(input) => this.onChange(input)}
              layoutCandidates={this.state.currentLang === 'zh' ? layoutCandidates : {}}
            />
          </div>
        </div>
      </div>
    );
  }
}

SearchPlace.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  searchPlacePlaceholder: PropTypes.string,
  searchType: PropTypes.string,
  selectedEntries: PropTypes.object.isRequired,
  isPMREnabled: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY] || {};

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(SearchPlace);
