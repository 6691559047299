/**
 * Declare pages used by the app
 * (Unnecessary pages won't be compiled nor included in js build)
 *
 * A page definition usually looks like this:
 *
 * key      : a unique identifier for the page (allowing to do: `navigate(HOME_PAGE_KEY);` or in redux reducers: `state[HOME_PAGE_KEY]`)
 * path     : used for routing, accessing directly to a page, bookmarking
 * component: React High Order Component for the page
 * elId     : unique id of the created <div id={elId}> element (@see `Router.mount()`) on which ReactDOM mounts the component
 */

// import aircraftPageConfig        from 'src/pages/aircraft/config';
// import choosePageConfig          from 'src/pages/choose-profile/config';
// import docunitPageConfig         from 'src/pages/docunit/config';
// import eventPageConfig           from 'src/pages/event/config';
// import exhibitorPageConfig       from 'src/pages/exhibitor/config';
// import experiencesItemPageConfig from 'src/pages/experiences_item/config';
import favoritesPageConfig from 'src/pages/favorites/config';
// import flightsSchedulePageConfig from 'src/pages/flights_schedule/config';
// import homePageConfig            from 'src/pages/home/config';
import hoursPageConfig from 'src/pages/hours/config';
import inboxPageConfig from 'src/pages/inbox/config';
import listPageConfig from 'src/pages/list/config';
import mobigeoPageConfig from 'src/pages/mobigeo/config';
// import mediasPageConfig          from 'src/pages/medias/config';
// import newProductPageConfig      from 'src/pages/new-product/config';
// import pmrPageConfig             from 'src/pages/pmr/config';
import restaurantPageConfig from 'src/pages/restaurant/config';
import monumentPageConfig from 'src/pages/monument/config';
import happeningPageConfig from 'src/pages/happening/config';
import searchPageConfig from 'src/pages/search/config';
import servicePageConfig from 'src/pages/service/config';
import itineraryPageConfig from 'src/pages/itinerary/config';
// import speakerPageConfig         from 'src/pages/speaker/config';

/** ********* TO UPDATE FOR EVERY PROJECT ************** */
/** ***** DECLARE PAGES USED FOR THIS PROJECT ********** */
export default [favoritesPageConfig, hoursPageConfig, inboxPageConfig, listPageConfig, mobigeoPageConfig, restaurantPageConfig, monumentPageConfig, happeningPageConfig, searchPageConfig, servicePageConfig, itineraryPageConfig];