export var USE_TRANSITION = false;

/**
 * @type {Number}
 */
export var TRANSITION_DURATION = 200; // milliseconds

/**
 * Various transitions
 * @type {Object}
 */
export var TYPE = {
  initial: 'initial',
  forward: 'forward',
  backward: 'backward'
};

/**
 * Define start style for each transition type
 * @param {string} toggleMode
 * @type  {Object}
 */
var _getCommonStartStyle = function _getCommonStartStyle(toggleMode) {
  var style = {
    display: 'block',
    zIndex: 2,
    opacity: 0.4
  };
  if (toggleMode === 'visibility') {
    style.visibility = 'visible';
  }
  return style;
};
export var getStartStyle = function getStartStyle(type, toggleMode) {
  var style;
  switch (type) {
    case TYPE.initial:
      style = {
        left: '0px',
        top: '50px'
      };
      break;
    case TYPE.forward:
      style = {
        left: "".concat(window.innerWidth, "px"),
        top: '0px'
      };
      break;
    case TYPE.backward:
      style = {
        left: "".concat(-window.innerWidth, "px"),
        top: '0px'
      };
      break;
    default:
      console.error("Unsupported start transition type: ".concat(type));
      style = {};
  }
  return Object.assign(style, _getCommonStartStyle(toggleMode));
};

/**
 * End style, common for every transition
 * @param {string} toggleMode
 * @type  {Object}
 */
export var getEndStyle = function getEndStyle(toggleMode) {
  // Animated properties when `USE_TRANSITION` is set to true:
  var style = {
    left: '0px',
    top: '0px',
    opacity: 1
  };
  return style;
};

/**
 * Previous page style once transition has completed
 * @param {string} toggleMode
 * @type  {Object}
 */
export var getResetStyle = function getResetStyle(toggleMode) {
  var style = {
    zIndex: 0
  };
  if (toggleMode === 'visibility') {
    style.visibility = 'hidden';
  } else {
    style.display = 'none';
  }
  return style;
};