import React from 'react';
import GenericIcon from 'src/components/generic-icon/GenericIcon';
import { capitalize } from 'src/core/util/StringUtil';

export default function(props) {
  const { titleBack, actions, children, setRef } = props;
  return (
    <div
      className="go-back-cat"
      onPointerUp={(e) => {
        if (e) {
          e.stopPropagation();
          e.preventDefault();
        }
        actions.navigateBack();
      }}
      ref={setRef}
    >
      <GenericIcon
        style={{
        }}
        className={'list-el-chevron chevron-back chevron '}
      />
      {titleBack && <span className="go-back-title">{capitalize(titleBack)}</span>}
      {children}
    </div>
  );
}