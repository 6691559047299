import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { FORCE_ONLINE_ASSETS } from 'app-customs/config/dataConfig';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import GenericIcon from 'src/components/generic-icon/GenericIcon.js';

import './Description.scss';

class Description extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
    };
  }

  render() {
    const {
      description,
      logoFileName,
      preview,
      isLogoFullWidth,
      labels,
      onClick,
      className,
      item,
      dataType,
    } = this.props;
    const { opened } = this.state;

    const hasDescription = typeof description === 'string' && description.length > 0;
    const imgElement = logoFileName ? (
      <img
        src={getUrl(`/${logoFileName}`, FORCE_ONLINE_ASSETS?.includes(dataType) ? true : undefined)}
        alt="logo"
      />
    ) : null;

    const shouldPreview = preview && hasDescription && description.length > 400;
    const previewDescription = !opened && shouldPreview;
    const click = onClick && typeof onClick === 'function' ? onClick : () => {};

    return (
      <div className={`fiche-description ${className || ''}`} onClick={click}>
        {(isLogoFullWidth || !hasDescription) && imgElement && (
          <div
            className="logo img-full-width"
            style={{ backgroundImage: `url(${getUrl(`/${logoFileName}`, true)})` }}
          />
        )}

        {hasDescription && (
          <div className="prop-name">
            {previewDescription && <div className="bottom-shadow" />}
            <div className={`prop-description ${previewDescription ? 'preview' : ''}`}>
              {!isLogoFullWidth && imgElement && <div className="logo img-left">{imgElement}</div>}

              {hasDescription && <p className='description-text-content' dangerouslySetInnerHTML={{ __html: description }} />}
            </div>
            {previewDescription && (
              <div className="btn-expand" onClick={() => this.setState({ opened: true })}>
                {labels.common.seeMore}
              </div>
            )}
            {!previewDescription && shouldPreview && (
              <div className="btn-expand" onClick={() => this.setState({ opened: false })}>
                {labels.common.seeLess}
              </div>
            )}
            {(item.phone || item.email || item.website) && (
              <div className="fiche-contact">
                {item.phone && (
                  <div className="fiche-contact-item">
                    <i>
                      <GenericIcon
                        style={{display: "inline-block"}}
                        className={'phone-icon'}
                      />
                    </i>
                    <span>{item.phone}</span>
                  </div>
                )}
                {item.email && (
                  <div className="fiche-contact-item">
                    <i>
                      <GenericIcon
                        style={{display: "inline-block"}}
                        className={'email-icon'}
                      />
                    </i>
                    <span>{item.email}</span>
                  </div>
                )}
                {/* {item.website && (
                  <div className="fiche-contact-item">
                    <i>
                      <img src={'./icons/web-borne.svg'} alt="phone-img" />
                    </i>
                    <span>{item.website}</span>
                  </div>
                )} */}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Description.propTypes = {
  dataType: PropTypes.string,
  labels: PropTypes.object,
  onClick: PropTypes.func,
  logoFileName: PropTypes.string,
  description: PropTypes.string,
  isLogoFullWidth: PropTypes.bool,
  preview: PropTypes.bool,
};

export default Description;
