import React from 'react';
import PropTypes from 'prop-types';

// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';

import config from 'app-customs/config/config';
import {
  VALID_DATA_TYPES,
  DATA_TYPE_BUILDINGS,
  DATA_TYPE_FLOORS,
  NON_CATEGORIES_DATA_TYPE,
  DATA_TYPE_GMAP_PLACES,
} from 'app-customs/config/dataConfig';

import {
  convertDataTypeToMobigeoType,
  getSortedAndTransformedData,
} from 'src/core/data-and-assets/Db';

import {
  elementPropsGetters,
  onClickOnLi,
  onClickOnNote,
  HIGHLIGHTED_PROPS,
  ROW_BACKGROUND_COLOR_ON_CLICK,
  DISABLE_FAVORITE_BY_DATATYPE,
} from 'app-customs/config/listConfig';

// App modules
import ListElement, { ORIGINAL_PROP_PREFIX } from './ListElement';
import Loader from 'src/components/loader/Loader';
import Highlight from './Highlight';
import NoResult from 'src/components/no-result/NoResult';
import { findParentNode } from 'src/core/util/DomTools';

import { isCordovaContext } from 'src/core/util/browser';

import * as Notes from 'src/core/notes/Notes';

// import * as actions from 'src/store/actions';

import './List.scss';
import { toggleElementCollapse } from './listUtil';

/**
 * Return helpers (functions) to get props for list elements
 * @param  {string} dataType
 * @param  {object} favorites
 * @param  {boolean} displayFavorites
 * @param  {object} userData
 * @return {object}
 */
function getElementHelpers(dataType, favorites, pageKey, displayFavorites, userData) {
  const COMMON_HELPERS = {
    key: (row) => row.id,
    'data-id': (row) => row.id,
    'data-type': () => dataType,
    'data-collapse-target': (cat) => (cat.children ? cat.id : null),
  };

  if (displayFavorites) {
    COMMON_HELPERS.isFavorite = (row) => {
      return favorites && favorites.indexOf(row.id) !== -1;
    };
  }

  let helpers = elementPropsGetters(dataType, pageKey, userData);
  if (!helpers) {
    console.error(`Type ${dataType} is not handled yet`);
  } else {
    return Object.assign({}, COMMON_HELPERS, helpers);
  }
}

/**
 * LIST COMPONENT
 */
class List extends React.PureComponent {
  state = {
    key: 0,
  };
  catsIdCollapsed = [];
  listContainer = null;
  scrollTop = null;
  Db = [DATA_TYPE_BUILDINGS, DATA_TYPE_FLOORS].reduce((currentObj, dataType) => {
    let partDatatype = getSortedAndTransformedData()[dataType];
    return { ...currentObj, [dataType]: partDatatype };
  }, {});
  componentDidMount() {
    // on back when a category has been selected, collapse the right cat
    this.listContainer.addEventListener('scroll', this.handleScroll);
    const { dataType, listDisplayState } = this.props;
    const { catsIdCollapsed, scrollTop } = (listDisplayState && listDisplayState[dataType]) || {};
    if (catsIdCollapsed && catsIdCollapsed.length) {
      catsIdCollapsed.forEach((catId) => {
        toggleElementCollapse(catId, dataType);
      });
      this.listContainer.scrollTop = scrollTop;
      this.catsIdCollapsed = catsIdCollapsed;
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { dataType, listDisplayState } = prevProps;

    if (
      !(
        (this.props.listDisplayState && this.props.listDisplayState[dataType]) ||
        (listDisplayState && listDisplayState[dataType])
      )
    ) {
      if (this.props.listDisplayState !== listDisplayState) this.resetList();
    }
  }
  componentWillUnmount() {
    this.listContainer.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (this.listContainer === null) return;
    this.scrollTop = this.listContainer.scrollTop; // listen scroll component and save it on clickLi
  };

  /**
   * Apply helpers to generate attributes value
   * @param  {*}      item
   * @param  {object} helpers
   * @param  {RegExp} highlightRegexp
   * @return {object}
   */
  getRowProps(item, helpers, highlightRegexp) {
    if (item.isSeparator === true) {
      return item;
    }
    return Object.keys(helpers).reduce((elProps, key) => {
      if (key === 'useForIndexLetter') {
        // Ignore this field (used to override the string used by side index letter getter)
        return elProps;
      }

      elProps[key] = helpers[key]({ ...this.Db, ...item });

      // Apply highlight
      if (highlightRegexp && HIGHLIGHTED_PROPS.indexOf(key) !== -1 && elProps[key]) {
        // Keep a copy of original value
        elProps[ORIGINAL_PROP_PREFIX + key] = elProps[key];

        elProps[key] = Highlight.apply(elProps[key], highlightRegexp);
      }
      return elProps;
    }, {});
  }

  getChildren(item) {
    const children = (item.children && item.children.items) || [];
    const highlightRegexp = Highlight.getRegexp(this.props.highlight);
    return (
      <ul
        className="item-collapse hide"
        data-collapse-id={item.id}
        style={
          {
            /* maxHeight: children.length * 400 + 'px' */
          }
        } // TODO? caculate children recursive and set max-height
      >
        {children &&
          children.map((child, index) => {
            const itemDataType = child.listType || this.props.dataType;
            let itemHelpers = getElementHelpers(
              itemDataType,
              this.props.displayFavorites && this.props.favorites
                ? this.props.favorites[itemDataType]
                : null,
              this.props.pageKey,
              this.props.displayFavorites,
              this.props.userData
            );
            return (
              <div key={'li-child-' + index}>
                <ListElement
                  campaignGraphicCharter={this.props.campaignGraphicCharter}
                  dataType={itemDataType}
                  isFastAndUgly={this.props.isFastAndUgly}
                  showGoToButton={this.props.showGoToButton}
                  goToButtonLabel={this.props.showGoToButton ? this.props.labels.map.goTo : ''}
                  onPointerDown={this.pointerDownOnLi}
                  onPointerUp={this.pointerUpOnLi}
                  labels={this.props.labels}
                  {...this.getRowProps(child, itemHelpers, highlightRegexp)}
                />
                {child.children && this.getChildren(child)}
              </div>
            );
          })}
      </ul>
    );
  }

  /**
   * Generate content
   * @return {array}
   */
  getElements() {
    if (this.props.isPending === true) {
      return <Loader labels={this.props.labels} />;
    }

    const highlightRegexp = Highlight.getRegexp(this.props.highlight);

    let listElements = [];

    this.props.items.forEach((item, index) => {
      let itemDataType = item.listType || this.props.dataType;
      let displayFavorites =
        this.props.displayFavorites === true
          ? !DISABLE_FAVORITE_BY_DATATYPE.includes(itemDataType)
          : false;
      let itemHelpers = getElementHelpers(
        itemDataType,
        displayFavorites && this.props.favorites ? this.props.favorites[itemDataType] : null,
        this.props.pageKey,
        displayFavorites,
        this.props.userData
      );

      let key = item.id ? `${itemDataType}-${item.id}-${index}` : `${itemDataType}-${index}`;
      if (item) {
        listElements.push(
          <div className="list-collapse" key={key}>
            <ListElement
              campaignGraphicCharter={this.props.campaignGraphicCharter}
              dataType={itemDataType}
              isFastAndUgly={this.props.isFastAndUgly}
              showGoToButton={this.props.showGoToButton}
              goToButtonLabel={this.props.showGoToButton ? this.props.labels.map.goTo : ''}
              prefix={this.props.labels.common.prefixFloor}
              onPointerDown={this.pointerDownOnLi}
              onPointerUp={this.pointerUpOnLi}
              {...this.getRowProps(item, itemHelpers, highlightRegexp)}
            />
            {this.getChildren(item)}
          </div>
        );
      }
    });

    return listElements;
  }
  resetList() {
    this.setState({ key: this.state.key + 1 });
  }

  render() {
    if (!this.props.dataType) {
      return null;
    }
    // Nothing found
    if ((!this.props.items || !this.props.items.length) && this.props.isPending !== true) {
      return <NoResult labels={this.props.labels} />;
    }

    const ulClassNames = ['list-component', this.props.dataType];
    if (this.props.isFastAndUgly) {
      ulClassNames.push('list-is-fast-and-ugly');
    }
    if (this.props.className) {
      ulClassNames.push(this.props.className);
    }
    if (NON_CATEGORIES_DATA_TYPE.indexOf(this.props.dataType) > -1) {
      ulClassNames.push('item-child');
    }

    return (
      <ul
        key={this.state.key}
        ref={(ref) => (this.listContainer = ref)}
        className={ulClassNames.join(' ')}
        data-search-type={this.props.searchType}
      >
        {this.getElements()}
      </ul>
    );
  }

  pointerDownOnLi = (e) => {
    this.CLientY = e.clientY;
  };

  pointerUpOnLi = (e) => {
    const YupDiff = e.clientY - this.CLientY;
    const YdownDiff = this.CLientY - e.clientY;
    if (YupDiff < 20 && YdownDiff < 20) {
      this.handleClickOnLi(e);
    }
  };

  /**
   * Handle click on list (row, fav icon, ...)
   * @param {object} e: event
   */

  handleClickOnLi = (e) => {
    let target = e.target;

    let liEl;
    if (target.tagName === 'LI') {
      liEl = target;
    } else {
      liEl = findParentNode(target, (el) => el.tagName === 'LI');
    }

    if (!liEl) {
      // User clicked on the <ul> but not on a <li>, e.g bottom of the list. simply ignore it.
      // console.warn(LOG_PREF+'Could not find <li> related to event target:', target);
      return;
    }

    // No action when separator is clicked
    if (liEl.classList.contains('horizontal-separator')) {
      return;
    }

    // Click on favorite icon
    if (target.classList.contains('star')) {
      const { id, type } = liEl.dataset,
        isFav = target.classList.contains('is-fav');

      this.props.actions.toggleFavorite(id, type, isFav);

      // Click on "go to" button
    } else if (target.classList.contains('go-to')) {
      let { type, originalId, id } = liEl.dataset;
      if (type === DATA_TYPE_GMAP_PLACES && !isCordovaContext()) {
      } else if (type === DATA_TYPE_GMAP_PLACES && isCordovaContext()) {
        this.props.actions.showOnePoiOnGoogleMap({ id: id, type: type });
      } else {
        type = convertDataTypeToMobigeoType(type);
        this.props.actions.dispatchItinerary({
          placeId: this.props.contextualPlaceId,
          type: type,
          originalId: originalId,
        });

        // Close dialog
        if (typeof this.props.onElementSelected === 'function') {
          this.props.onElementSelected();
        }
      }
    } else {
      // Click on item
      if (liEl && typeof liEl.dataset.isClickable !== 'undefined') {
        // const initialBackgroundColor = liEl.style.backgroundColor;
        // liEl.style.backgroundColor = ROW_BACKGROUND_COLOR_ON_CLICK;
        window.setTimeout(() => {
          // liEl.style.backgroundColor = initialBackgroundColor;

          // Close dialog
          if (typeof this.props.onElementSelected === 'function') {
            this.props.onElementSelected();
          }

          // Execute optionnal func when click on li
          if (this.props.onItemClick && typeof this.props.onItemClick === 'function') {
            this.props.onItemClick();
          }
          if (
            this.props.onSearchedItemClick &&
            typeof this.props.onSearchedItemClick === 'function'
          ) {
            const { id, memberId, memberOriginalId, memberType } = liEl.dataset;
            const POI = {
              id: parseInt(memberId, 10),
              originalId: memberOriginalId,
              type: memberType,
              placeId: parseInt(id, 10),
            };
            this.props.onSearchedItemClick();
          }
          //execute collapse if data-collapse-id is set
          if (liEl && typeof liEl.dataset.collapseTarget !== 'undefined') {
            const targetCollapse = toggleElementCollapse(
              liEl.dataset.collapseTarget,
              this.props.dataType
            );
            if (targetCollapse) {
              // toggle catId present
              !this.catsIdCollapsed.includes(liEl.dataset.collapseTarget)
                ? this.catsIdCollapsed.push(liEl.dataset.collapseTarget)
                : (this.catsIdCollapsed = this.catsIdCollapsed.filter(
                    (cat) => cat !== liEl.dataset.collapseTarget
                  ));
              this.props.actions.setListDisplayState({
                [this.props.dataType]: { catsIdCollapsed: this.catsIdCollapsed },
              });
            } else {
              onClickOnLi(liEl, this.props.actions, this.props.labels, this.props.pageKey);
            }
          }
          // Execute configured action
          if (this.props.isNotesList) {
            onClickOnNote(liEl, this.props.actions, this.props.labels, this.props.pageKey);
          } else if (typeof liEl.dataset.collapseTarget === 'undefined') {
            this.props.actions.setListDisplayState({
              [this.props.dataType]: { scrollTop: this.scrollTop },
            });
            onClickOnLi(liEl, this.props.actions, this.props.labels, this.props.pageKey);
          }
        }, config.DELAY_BEFORE_CLICK_ACTION);
      }
    }
  };
}

List.propTypes = {
  notes: PropTypes.object,
  items: PropTypes.array,
  isPending: PropTypes.bool,
  dataType: PropTypes.oneOf(VALID_DATA_TYPES).isRequired,
  displayFavorites: PropTypes.bool,
  favorites: PropTypes.object,
  isFastAndUgly: PropTypes.bool,
  showGoToButton: PropTypes.bool,
  // isPMREnabled    : PropTypes.bool.isRequired,
  userData: PropTypes.object,

  // i18n labels (set for current language)
  labels: PropTypes.object.isRequired,

  // if specified, any occurence of this string will be emphasised (useful when displaying of list of search results)
  highlight: PropTypes.string,

  clickOnTypeBar: PropTypes.func,

  // Optional function to execute when an element has been selected
  onElementSelected: PropTypes.func,

  // Optional argument for function `onClickOnLi`
  pageKey: PropTypes.string,

  // Optional context, e.g data list dialog on map
  contextualPlaceId: PropTypes.number,

  // Optional context for search results
  searchType: PropTypes.string,

  // optional action on for click on li
  onItemClick: PropTypes.func,

  // optional action on for click on li searched
  onSearchedItemClick: PropTypes.func,
};

export default List;

/*
const mapStateToProps = (state, ownProps) => {
    return {isPMREnabled: state[MOBIGEO_PAGE_KEY].isPMREnabled}
}
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
*/
