import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getCurrentPageProps } from 'src/core/navigation/CurrentRoute';
import ChooseLangInline from 'src/components/choose-lang-inline/ChooseLangInline';

import * as actions from 'src/store/actions';

import './chooseLang.scss';

export const COMPONENT_KEY = 'ChooseLangFullScreen';
export const CONTAINER_DOM_ID = 'choose-lang-fullScreen-container';

const DEFAULT_DIALOG_PROPS = {
  // TODO: Export that to app-customs/config/dialogsConfig ?
};

/**
 * Display a dialog (modal) allowing user to change current language for the whole app
 * @param  {object} props
 */
function ChooseLangFullScreen({ supportedLangs, isOpen, labels, actions, options }) {
  const currentLangId = labels.id;

  /**
   * Actions when user has clicked a flag
   * @param  {string} lang
   */
  function handleFlagSelected(lang) {
    if (lang !== currentLangId) {
      actions.setLanguage(lang);
    }
    actions.setIndexMenuItemActive(getCurrentPageProps().indexMenuItem);
    actions.hideChooseLangFullScreen();
    if (lang === currentLangId) {
      window.MobiGeo.Map.cameraAnimation({ alpha: null, beta: 0.00001 }, null);
    }
  }

  const dialogProps = {
    open: isOpen,
    onClose: actions.hideChooseLangFullScreen, // important, to update `open` value in all cases
  };

  return isOpen ? (
    <div className="choose-lang">
      <div>
        <div id="cl-langs">
          <ChooseLangInline
            labels={labels}
            actions={actions}
            onFlagClick={handleFlagSelected}
            options={options}
            displayType="label"
          />
        </div>
        <div className="choose-lang-instruction">
          <p>{labels.common.chooseLangInstruction}</p>
        </div>
        <span className="meta-app-version minor-text-info">{`v${process.env.APP_VERSION}`}</span>
      </div>
    </div>
  ) : null;
}

ChooseLangFullScreen.propTypes = {
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(ChooseLangFullScreen);
