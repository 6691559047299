import config from 'app-customs/config/config';

import { SHOW_MOBILESPOT_BUTTON } from 'app-customs/config/mobigeoConfig';

import {
  CONFIG_JSON_LOADED,
  POLL_CONFIG_LOADED,
  DATA_ASSETS_UPDATED,
  HAS_NAVIGATED,
  LANG_CHANGED,
  MOBIGEO_IS_RESTARTING,
  MOBIGEO_LOADED,
  MOBIGEO_RELOAD,
  PROFILE_CHANGED,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_LOCATION_CAPABILITY_STATUS,
  TOGGLE_PMR_STATUS,
  TOGGLE_CUSTOM_KEYBOARD,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  WINDOW_RESIZED,
  PLACE_SEARCH_PERFORMED,
  CLEAR_PLACE_RESULTS,
  HIDE_SEARCH_PLACE_DIALOG,
  SEARCHED_PLACE_SELECTED,
  SET_SEARCH_PLACE_DIALOG_CANCELABLE,
  SHOW_SEARCH_PLACE_DIALOG,
  RESET_MAP
} from 'src/store/actionTypes';

import {
  configJsonLoaded,
  togglePageAfterNavigation,
  pollConfigLoaded,
  getPageCommonDefaultState,
  langChanged,
  profileChanged,
  toggleLocationStatus,
  toggleLocationCapabilityStatus,
  togglePMRStatus,
  toggleCustomKeyboard,
  toggleMenu,
  updateObject,
  updatePageState,
  windowResized,
} from 'src/store/reducers/commons';

import { MOBIGEO_PAGE_KEY } from 'src/pages/pagesKeys';

import MobigeoContext from './MobigeoContext';
import SearchPlaceTypes from 'src/components-standalone/search-place-dialog/SearchPlaceTypes';

const Immutable = require('immutable');

const getDefaultState = () => ({
  ...getPageCommonDefaultState(MOBIGEO_PAGE_KEY),
  hasMobileSpotButton: SHOW_MOBILESPOT_BUTTON === true,
  mobigeoApiKey: config.MAP.MOBIGEO.API_KEY,
  searchButtonInToolbar: false, // override
  isOpen: true,
  searchType: SearchPlaceTypes.poi,
  selectedEntries: Immutable.Map({}),
  searched: null,
  results: null,
  resultsType: null,
  totalCount: 0,
  userIsLocated: false,
});

/**
 * Mobigeo initialization:
 *   - App is ready for map loading when data is loaded
 *
 * Mobigeo restart if:
 *   - data has been updated OR at least an asset whose path contains 'files/maps/' has been updated
 *   - map is not currently displayed
 *
 * @param {boolean} value
 */
const _mapShouldRestart = (state, action, value) =>
  updateObject(state, { shouldRestart: MobigeoContext.isNotLoaded() ? false : value });


const _dataUpdated = (state, action) => {
  // Detect when app has initialized (meaning data and assets are ready for MobiGeo)
  if (state.isDataReady !== true) {
    return updateObject(state, { isDataReady: true });
  }
  return state;
};

const _showSearchPlaceDialog = (state, action) => {
  return updateObject(state, {
    isCancelable: false,
    searched: null,
    results: null,
    resultsType: null,
    searchType: action.searchType,
  });
};

const _hideSearchPlaceDialog = (state, action) => {
  return updateObject(state, {
    selectedEntries: {},
    searchType: null,
  });
};

const _setSearchPlaceDialogCancelable = (state, action) => {
  return updateObject(state, { isCancelable: action.value });
};

const _placeSearchPerformed = (state, action) => {
  return updateObject(state, {
    status: action.status,
    searched: action.searched,
    results: action.results,
    resultsType: action.searchType,
    totalCount: action.totalCount,
    relaunch: false,
  });
};

const _clearPlaceResults = (state, action) => {
  const selectedEntries = state.selectedEntries.toJS ? state.selectedEntries.toJS() : {};
  selectedEntries[action.searchType] = {};

  return updateObject(state, {
    status: action.status,
    results: null,
    resultsType: null,
    searched: null,
    totalCount: 0,
    selectedEntries: Immutable.Map(selectedEntries),
  });
};

const _searchedPlaceSelected = (state, action) => {
  if (action.searchType === SearchPlaceTypes.poi) {
    // Let the middleware handle this one
    return state;
  }

  const selectedEntries = state.selectedEntries.toJS ? state.selectedEntries.toJS() : {};
  selectedEntries[action.searchType] = action.entry;

  return updateObject(state, {
    results: null,
    resultsType: null,
    searched: null,
    totalCount: 0,
    selectedEntries: Immutable.Map(selectedEntries),
  });
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);
    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, MOBIGEO_PAGE_KEY);
    case POLL_CONFIG_LOADED:
      return pollConfigLoaded(state, action);
    case DATA_ASSETS_UPDATED:
      return _dataUpdated(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);
    case MOBIGEO_IS_RESTARTING:
      return _mapShouldRestart(state, action, false);
    case MOBIGEO_LOADED:
      return _mapShouldRestart(state, action, false);
    case MOBIGEO_RELOAD:
      return _mapShouldRestart(state, action, true);
    case PROFILE_CHANGED:
      return profileChanged(state, action, MOBIGEO_PAGE_KEY);
    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);
    case TOGGLE_LOCATION_CAPABILITY_STATUS:
      return toggleLocationCapabilityStatus(state, action);
    case TOGGLE_PMR_STATUS:
      return togglePMRStatus(state, action);
    case TOGGLE_CUSTOM_KEYBOARD:
      return toggleCustomKeyboard(state, action);
    case TOGGLE_MENU:
      return toggleMenu(state, action, MOBIGEO_PAGE_KEY); // TODO: menuOptions.disableSwipeToOpen: true ?
    case UPDATE_PAGE_STATE:
      return updatePageState(state, action, MOBIGEO_PAGE_KEY);
    case WINDOW_RESIZED:
      return windowResized(state, action);
    case PLACE_SEARCH_PERFORMED:
      return _placeSearchPerformed(state, action);
    case SHOW_SEARCH_PLACE_DIALOG:
      return _showSearchPlaceDialog(state, action);
    case HIDE_SEARCH_PLACE_DIALOG:
      return _hideSearchPlaceDialog(state, action);
    case SET_SEARCH_PLACE_DIALOG_CANCELABLE:
      return _setSearchPlaceDialogCancelable(state, action);
    case SEARCHED_PLACE_SELECTED:
      return _searchedPlaceSelected(state, action);
    case CLEAR_PLACE_RESULTS:
      return _clearPlaceResults(state, action);
    default:
      return state;
  }
};
