module.exports = {
  "name": "printemps-borne",
  "client": "Printemps Haussmann",
  "description": "Projet MobiGeo pour l'application Printemps Haussmann",
  "pluginId": "mobigeo-plugin-ph",
  "validLangs": ["en", "fr", "zh"],
  "version": {
    "mainVersion": "1.1.0",
    "buildVersion": {
      "iOS": "1",
      "android": "1",
      "web": "16"
    }
  },
  "native": {
    "author": "Mobile-Spot",
    "backgroundColor": "0xffffff",
    "iosAppStoreUrl": "https://itunes.apple.com/app/printemps-haussmann/id853771459",
    "iosBuildTarget": "10.0",
    "playStoreUrl": "https://play.google.com/store/apps/details?id=com.printemps.haussmann",
    "androidFadeSplashScreenDuration": 750,
    "app": "app-react",
    "appleTeamId": "2BAP3P29V2",
    "name": {
      "default": "Haussmann DEV"
    },
    "id": "com.printemps.haussmann.dev",
    "appleAppId": "1012404293"
  },
  "data": {
    "default": "ahd5Ahqu2oof"
  },
  "features": ["location", "map.route", "favorites"],
  "location": {
    "type": ["nao"],
    "key": "l55t8GXcOzwO_FDgNLiyYA",
    "files": [{
      "5026.jscx": "assets/pdbs"
    }, {
      "app.json": "assets"
    }],
    "registering": {
      "location": true,
      "geofencing": false
    },
    "mapping": {},
    "forceSynchronize": true
  },
  "undeliveredFolders": ["source", "exports"],
  "cordova": true,
  "crypto": false,
  "customFileUrlScheme": "msfile",
  "geoGroup": {},
  "relatedRepos": [{
    "src": "git@bitbucket.org:mobilespotdev/mobigeo.git",
    "postCommands": ["cd mobigeo && npm install"],
    "branch": "inte/printemps/interactive-terminal"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/mobigeo-customs-master.git",
    "dest": "mobigeo/mobigeo-customs",
    "branch": "inte/printemps/interactive-terminal"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/app-react.git",
    "branch": "inte/printemps/interactive-terminal"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/data-master.git",
    "dest": "app-react/app-customs",
    "branch": "inte/printemps/interactive-terminal",
    "postCommands": ["cd app-react && yarn", "cd app-react && npm run set-env dev", "cd app-react && npm run download-assets", "cd app-react && npm run download-data-files", "cd app-react && npm run update-mobigeo"]
  }],
  "web": {
    "url": "ph-ecran.ms-dev.mobile-spot.com",
    "redirectUrlQrCode": "ph.ms-dev.mobile-spot.com"
  }
};