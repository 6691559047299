import React from 'react';
import PropTypes from 'prop-types';

import Url from 'src/components/url/Url';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

function WebsiteRow(props) {
  return !props.website ? null : (
    <div className="free-row">
      <div className="prop-img">
        <div className="prop-left">
          <GenericIcon className="link-row" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          className="prop-right "
        >
          <Url callback={props.actions.linkClicked} href={props.website} label={props.website} />
        </div>
      </div>
    </div>
  );
}

WebsiteRow.propTypes = {
  website: PropTypes.string,
  actions: PropTypes.object.isRequired,
};

export default WebsiteRow;
