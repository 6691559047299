import { HAPPENINGS_PAGE_KEY } from 'src/pages/pagesKeys';
import { DATA_TYPE_HAPPENINGS } from 'app-customs/config/dataConfig';
import reducer from './HappeningPageReducer';
import HappeningPage from './HappeningPage';
import { fetchHappeningsDataFromDB } from 'src/core/happenings/happenings';

export default {
  key: HAPPENINGS_PAGE_KEY,
  path: '/happening',
  component: HappeningPage,
  elId: 'happening-page',
  getReduxReducer: (state, action) => reducer(state[HAPPENINGS_PAGE_KEY], action),
  isGranted: (props) => {
    if (Array.isArray(props.inputs)) {
      if (
        props.inputs.find((input) => input.dataType === DATA_TYPE_HAPPENINGS) &&
        fetchHappeningsDataFromDB()
      ) {
        return true;
      }
    }
    return !!fetchHappeningsDataFromDB()
    // return false;
  },
};
