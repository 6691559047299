import config from 'app-customs/config/config';

import { HIDE_LANG_FULLSCREEN, LANG_CHANGED, SHOW_LANG_FULLSCREEN } from 'src/store/actionTypes';

import { get as getLabels } from 'src/core/Lang';

import { langChanged, updateObject } from 'src/store/reducers/commons';

const getDefaultState = () => ({
  labels: getLabels(),
  isOpen: false,
  supportedLangs: config.SUPPORTED_LANGS,
});

const _toggleLangFullScreen = (state, action, value) => {
  return updateObject(state, {
    isOpen: value,
    options : action.options
  })
}

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case HIDE_LANG_FULLSCREEN:
      return _toggleLangFullScreen(state, action, false);
    case LANG_CHANGED:
      return langChanged(state, action);
    case SHOW_LANG_FULLSCREEN:
      return _toggleLangFullScreen(state, action, true)
    default:
      return state;
  }
};
