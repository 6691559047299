import React from 'react';
import PropTypes from 'prop-types';

import config from 'app-customs/config/config';

import FlagButton from './FlagButton';
import LabelButton from './LabelButton';

import './ChooseLangInline.scss';

function ChooseLangInline({ labels, actions, onFlagClick, displayType, options }) {
  if (Array.isArray(config.SUPPORTED_LANGS) !== true || config.SUPPORTED_LANGS.length < 2) {
    return null;
  }
  const renderFlag = (lang) => (
    <FlagButton
      key={lang}
      lang={lang}
      className={lang === labels.id ? 'is-current-lang' : null}
      labels={labels}
      onClick={onFlagClick || actions.setLanguage}
    />
  );

  const renderLabel = (lang) => (
    <LabelButton
      key={lang}
      lang={lang}
      className={'round-corner'}
      labels={labels}
      onClick={onFlagClick || actions.setLanguage}
    />
  );
  return (
    <div id="choose-lang-inline-container">
      {config.SUPPORTED_LANGS.map((lang) =>
        displayType === 'label' ? renderLabel(lang) : renderFlag(lang)
      )}
    </div>
  );
}

ChooseLangInline.propTypes = {
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  onFlagClick: PropTypes.func,
};

export default ChooseLangInline;
