var defaultConf = require('./config-default');
var BO_URL = 'boph4.0-data-dev.ms-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: "~/printemps/printemps-haussmann/".concat(BO_URL),
  // no trailing '/'
  BO_SERVER_ALIAS: 'ms-dev.mobilespot.prodleni.local',
  BO_SSH_USER: 'legacy',
  // '43322' //
  BO_SSH_PORT: '43122',
  // '43322' //

  DEPLOY_PATH: '~/printemps/printemps-haussmann/ph-ecran.ms-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-41991492-1',
  GOOGLE_ANALYTICS_ID_V4: 'G-G79BKT1JS0',
  projectId: 1014660025549,
  // GCM/FCM send id
  appId: 'B4396-9272A',
  // pushwoosh appid

  timerScreenSaver: 5000,
  // in second
  reloadTimer: 6000,
  randomTimeInterval: {
    min: 0,
    max: 20
  },
  TOU_LINK: {
    en: "https://".concat(BO_URL, "/cgu/cgu_en.html"),
    fr: "https://".concat(BO_URL, "/cgu/cgu_fr.html"),
    zh: "https://".concat(BO_URL, "/cgu/cgu_zh.html")
  }
});