import { LIST_PAGE_KEY } from 'src/pages/pagesKeys';
import { DATA_TYPE_PARTICIPANTS, DATA_TYPE_MEETING_CONTACTS } from 'app-customs/config/dataConfig';

import { isSessionValid } from 'src/core/login/LoginService';
import ListPage from './ListPage';
import reducer from './listPageReducer';

export default {
  key: LIST_PAGE_KEY,
  path: '/list',
  component: ListPage,
  elId: 'list-page',
  getReduxReducer: (state, action) => reducer(state[LIST_PAGE_KEY], action),
  isGranted: (props) => {
    if (Array.isArray(props.inputs)) {
      if (props.inputs.find((input) => input.dataType === DATA_TYPE_PARTICIPANTS || input.dataType === DATA_TYPE_MEETING_CONTACTS)) {
        return isSessionValid();
      }
    }
    return true;
  },
  toggleMode: 'visibility',
};
