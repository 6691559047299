// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash-custom';

// Config
import { HAPPENINGS_PAGE_KEY } from 'src/pages/pagesKeys';
import { DATA_TYPE_HAPPENINGS } from 'app-customs/config/dataConfig';
// App modules
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import List from 'src/components/list/List';
import * as actions from 'src/store/actions';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import './HappeningPage.scss';

const LOG_PREF = '[HappeningPage] ';

class HappeningPage extends Component {
  constructor(props) {
    super(props);
    this.pageKey = HAPPENINGS_PAGE_KEY;
  }

  /**
   * Update string visible in browser tab/history/happenings
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(
        this.props.campaign && this.props.campaign.title ? this.props.campaign.title : ''
      );
    }
  }

  /**
   * String displayed in app toolbar
   * @return {string}
   */
  getPageTitle() {
    return this.props.campaign && this.props.campaign.title ? this.props.campaign.title : '';
  }

  componentDidMount() {
    this.fetchHappenings();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shouldFetch === true) {
      this.fetchHappenings();
    }
  }

  fetchHappenings = debounce(this.props.actions.fetchHappenings, 200);

  componentDidUpdate() {
    this.setDocumentContext();
  }

  render() {
    console.log(LOG_PREF + 'render');
    if (!this.props.isVisible) return null;
    const { campaign, happenings } = this.props;
    const campaignHappenings = happenings && happenings.slice(1);
    const bgColor = campaign && campaign.lump && campaign.lump.color;
    const colorTxt = campaign && campaign.lump && campaign.lump.colorTxt;
    return (
      <>
        <Menu
          options={this.props.menuOptions}
          actions={this.props.actions}
          labels={this.props.labels}
          profile={this.props.profile}
          associatedPageKey={this.pageKey}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          actions={this.props.actions}
          title={this.getPageTitle()}
          pageKey={this.pageKey}
          profile={this.props.profile}
          synchroFavBtnStatus={this.props.synchroFavBtnStatus}
          isSynchroFavFeatureEnabled={this.props.isSynchroFavFeatureEnabled}
          hasBackButton={this.props.backButtonInToolbar}
          listToolBarButton={this.props.listToolBarButton}
          listConfigToolBarButton={this.props.listConfigToolBarButton}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={this.props.searchButtonInToolbar}
          hasMenuButton={this.props.menuButtonInToolbar}
        />

        <div className="happening-container">
          {campaign && campaign.logo_file_name && (
            <img className="happening-image" src={getUrl(campaign.logo_file_name)} />
          )}
          {campaign && (campaign.description_title || campaign.description) && (
            <div
              className="happening-description"
              style={{ backgroundColor: bgColor, color: colorTxt }}
            >
              <h3 className="happening-description-title title-font">
                {campaign.description_title ? campaign.description_title : ''}
              </h3>
              <p className="happening-description-text content-font">
                {campaign.description ? (
                  <div dangerouslySetInnerHTML={{ __html: campaign.description }} />
                ) : (
                  campaign.description
                )}
              </p>
            </div>
          )}
          {campaignHappenings && !!campaignHappenings.length && (
            <List
              campaigngraphiccharter={{ bgColor, colorTxt }}
              dataType={DATA_TYPE_HAPPENINGS}
              items={campaignHappenings}
              searched={this.props.searched}
              isPending={this.props.isPending}
              favorites={this.props.favorites}
              favIconDisabled={this.props.favIconDisabled}
              userData={this.props.userData}
              actions={this.props.actions}
              labels={this.props.labels}
              showGoToButton={!!this.props.campaign}
              pageKey={HAPPENINGS_PAGE_KEY}
            />
          )}
        </div>
      </>
    );
  }
}

HappeningPage.propTypes = {
  campaign: PropTypes.object,
  happenings: PropTypes.array,
  favorites: PropTypes.object,
  isPending: PropTypes.bool,
  itemNotFound: PropTypes.bool,
  isSynchroFavFeatureEnabled: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  userData: PropTypes.object,
  // Common page props
  menuOptions: PropTypes.object.isRequired,
  profile: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isActive: PropTypes.func.isRequired,
  queryString: PropTypes.string.isRequired,
  setDocumentContext: PropTypes.func.isRequired,
  isLocationEnabled: PropTypes.bool,
  isVisible: PropTypes.bool, // set by togglePageAfterNavigation common reducer function
  // toolbar
  hasToolbar: PropTypes.bool,
  synchroFavBtnStatus: PropTypes.string,
  homeButtonInToolbar: PropTypes.bool,
  backButtonInToolbar: PropTypes.bool,
  searchButtonInToolbar: PropTypes.bool,
  favButtonInToolbar: PropTypes.bool,
  menuButtonInToolbar: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[HAPPENINGS_PAGE_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(HappeningPage);
