import {
  CLEAR_PLACE_RESULTS,
  DATA_ASSETS_UPDATED,
  HIDE_SEARCH_PLACE_DIALOG,
  KEYBOARD_TOGGLED,
  LANG_CHANGED,
  MOBIGEO_USER_LOCATED,
  MOBIGEO_USER_UNLOCATED,
  PLACE_SEARCH_PERFORMED,
  SEARCHED_PLACE_SELECTED,
  SET_SEARCH_PLACE_DIALOG_CANCELABLE,
  SHOW_SEARCH_PLACE_DIALOG,
  TOGGLE_PMR_STATUS,
  TOGGLE_CUSTOM_KEYBOARD,
  WINDOW_RESIZED,
} from 'src/store/actionTypes';

import { MOBIGEO_PAGE_KEY } from 'src/pages/pagesKeys';

import { get as getLabels } from 'src/core/Lang';
import { get as getProfile } from 'src/core/Profile';

import { setDialogConfig } from 'src/core/dialog/DialogPersistence';

import {
  langChanged,
  setSearchPlacePlaceHolder,
  updateKeyboardState,
  toggleCustomKeyboard,
  updateObject,
  windowResized,
  togglePMRStatus,
} from 'src/store/reducers/commons';

import SearchPlaceTypes from './SearchPlaceTypes';

const Immutable = require('immutable');

const getDefaultState = () => ({
  labels: getLabels(),
  profile: getProfile(),
  isOpen: false,
  selectedEntries: Immutable.Map({}),
  searchType: null,
  userIsLocated: false,
});

const _showSearchPlaceDialog = (state, action) => {
  setDialogConfig({ isOpen: true, actionName: 'hideSearchPlaceDialog' });

  return updateObject(state, {
    isOpen: true,
    isCancelable: false,
    searched: null,
    results: null,
    resultsType: null,
    searchType: action.searchType,
  });
};

const _hideSearchPlaceDialog = (state, action) => {
  setDialogConfig({ isOpen: false, actionName: '' });
  
  return updateObject(state, {
    isOpen: false,
    selectedEntries: {},
    searchType: null,
  });
};

const _setSearchPlaceDialogCancelable = (state, action) => {
  return updateObject(state, { isCancelable: action.value });
};

const _placeSearchPerformed = (state, action) => {
  return updateObject(state, {
    status: action.status,
    searched: action.searched,
    results: action.results,
    resultsType: action.searchType,
    totalCount: action.totalCount,
    relaunch: false,
  });
};

const _clearPlaceResults = (state, action) => {
  const selectedEntries = state.selectedEntries.toJS ? state.selectedEntries.toJS() : {};
  selectedEntries[action.searchType] = {};

  return updateObject(state, {
    status: action.status,
    results: null,
    resultsType: null,
    searched: null,
    totalCount: 0,
    selectedEntries: Immutable.Map(selectedEntries),
  });
};

const _searchedPlaceSelected = (state, action) => {
  if (action.searchType === SearchPlaceTypes.poi) {
    // Let the middleware handle this one
    return state;
  }

  const selectedEntries = state.selectedEntries.toJS ? state.selectedEntries.toJS() : {};
  selectedEntries[action.searchType] = action.entry;

  return updateObject(state, {
    results: null,
    resultsType: null,
    searched: null,
    totalCount: 0,
    selectedEntries: Immutable.Map(selectedEntries),
  });
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CLEAR_PLACE_RESULTS:
      return _clearPlaceResults(state, action);
    case DATA_ASSETS_UPDATED:
      // This component is currently only used on MAP page,
      // beware to pass proper pageKey as argument if SearchPlageDialog is used on other pages
      return setSearchPlacePlaceHolder(state, action, MOBIGEO_PAGE_KEY);
    case HIDE_SEARCH_PLACE_DIALOG:
      return _hideSearchPlaceDialog(state, action);
    case TOGGLE_CUSTOM_KEYBOARD:
      return toggleCustomKeyboard(state, action);
    case KEYBOARD_TOGGLED:
      return updateKeyboardState(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);
    case MOBIGEO_USER_LOCATED:
      return updateObject(state, { userIsLocated: true });
    case MOBIGEO_USER_UNLOCATED:
      return updateObject(state, { userIsLocated: false });
    case PLACE_SEARCH_PERFORMED:
      return _placeSearchPerformed(state, action);
    case SEARCHED_PLACE_SELECTED:
      return _searchedPlaceSelected(state, action);
    case SET_SEARCH_PLACE_DIALOG_CANCELABLE:
      return _setSearchPlaceDialogCancelable(state, action);
    case SHOW_SEARCH_PLACE_DIALOG:
      return _showSearchPlaceDialog(state, action);
    case TOGGLE_PMR_STATUS:
      return togglePMRStatus(state, action);
    case WINDOW_RESIZED:
      return windowResized(state, action);
    default:
      return state;
  }
};
