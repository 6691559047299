// conf
import config from 'app-customs/config/config';

// app modules
import { addHttpIfNeeded, convertFileSrc } from 'src/core/util/JsTools';
import { getBindedActions } from 'src/store/bindedActions';
import * as Db from './Db';
import FileSystemHelper from './FileSystemHelper';

import { startUpdate, getUrl as updaterGetUrl } from './Updater';

// import TransitionQueuedActions from 'src/core/navigation/transition/TransitionQueuedActions';

const LOG_PREF = '[DataAssetsUtil] ';

let lastUpdateTime = null;

export const getLastUpdateTime = () => lastUpdateTime;
export const setLastUpdateTime = (value) => lastUpdateTime = value;

export function getUrl(url, useHttp, absolute) {
  //Keep distant absolute url
  if (url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1) {
    // Remove starting '/' if any
    value = url[0] === '/' ? url.substr(1) : url;
    return value;
  }
  // Force data and assets to be fetched from backend if !CORDOVA and !DEV
  if (useHttp !== false && global.isCordovaContext !== true && process.env.NODE_ENV === 'production') {
    useHttp = true;
  }

  let value;

  if (!url) {
    value = '';
  }
  if (useHttp === true) {
    if (url[0] !== '/') {
      url = `/${url}`;
    }
    value = addHttpIfNeeded(config.BO_URL + url, true);
  } else {
    // Remove starting '/' if any
    value = url[0] === '/' ? url.substr(1) : url;

    if (global.isCordovaContext) {
      value = updaterGetUrl(value);
    }
    if (absolute === true && value[0] !== '/' && value.indexOf('://') === -1) {
      const loc = document.location.pathname;
      value = loc.substr(0, loc.lastIndexOf('/') + 1) + value;
    }
  }
  value = convertFileSrc(value);
  return value.replace(/^public\//, '');
}

if (config.ENV === 'dev') {
  global.getUrl = getUrl;
}

function indicateDataAndAssetsReady() {
  getBindedActions().dataAssetsUpdated(['all']);
}

export const initialDbFetch = () => {
  Db.getAllDataByLang(indicateDataAndAssetsReady)
}

export const init = () => {
  if (!config.BO_URL) {
    // There is no cake backend for this project, so no db/assets to intialize
    indicateDataAndAssetsReady();
    getBindedActions().updaterInitialized();
    return;
  }

  // defer
  window.setTimeout(() => {
    // TransitionQueuedActions.performActionWhenNoTransition(() => {
    console.log(`${LOG_PREF}init`);
    Db.initialize(() => {
      if (global.isCordovaContext) {
        FileSystemHelper.initialize(() => {
          // Dispatch DB updated event only once assets are ready
          indicateDataAndAssetsReady();

          startUpdate();
        });
      } else {
        indicateDataAndAssetsReady();
      }
    });
    // });
  }, 1);
};
