import React from 'react';

import { getAlphabeticalListByIndexThreshold, DISABLE_FAVORITE_BY_DATATYPE } from 'app-customs/config/listConfig';

import AlphabeticalList from 'src/components/list/AlphabeticalList';
import List from 'src/components/list/List';
import TypeBar from 'src/components/type-bar/TypeBar';

import { DATA_TYPE_USERPROJECTS } from 'app-customs/config/dataConfig';

import STATUS from 'src/store/fetchStatuses';

export function renderList({
  items,
  status,
  favIconDisabled,
  favorites,
  userData,
  actions,
  labels,
  pageKey,
  listDisplayState,
  listHeight,
  backHeight,
}) {
  const dataTypes = Object.keys(items);
  return dataTypes.map((dataType) => {
    const _items = items[dataType];
    if (_items.length === 0) {
      return null;
    }
    const listProps = {
      key: dataType,
      actions,
      labels,
      favorites,
      isPending: status[dataType] === STATUS.PENDING,
      dataType,
      displayFavorites: favIconDisabled === true ? false : !DISABLE_FAVORITE_BY_DATATYPE.includes(dataType),
      pageKey,
      userData,
      listDisplayState,
    };

    let list;
    if (dataType === DATA_TYPE_USERPROJECTS) {
      listProps.items = _items && _items.data ? _items.data : _items;
      list = <List {...listProps} />;
    } else if (_items.data.all) {
      listProps.items = _items.data.all;
      list = <List {...listProps} />;
    } else {
      listProps.items = _items.data;
      listProps.indexes = Object.keys(_items.data);
      listProps.getSeparators = _items.getSeparators;
      listProps.contentByIndex = _items.length > getAlphabeticalListByIndexThreshold();

      list = (
        <AlphabeticalList
          parentHeight={listHeight}
          associatedPageKey={pageKey}
          {...listProps}
          itemsCount={_items.length}
          backHeight={backHeight}
        />
      );
    }

    // If several lists are shown, wrap with a type separator
    if (dataTypes.length > 1) {
      return (
        <TypeBar key={dataType} label={labels.data[dataType].plural}>
          {list}
        </TypeBar>
      );
    }
    return list;
  });
}

export function toggleElementCollapse(id, dataType) {
  const targetCat = document.querySelector(`[data-id="${id}"][data-type="${dataType}"]`);
  targetCat.classList.toggle('activated');
  const targetCollapse = document.querySelector(
    `[data-collapse-id="${id}"]`
  );
  const chevron = targetCat.querySelector(`.list-el-chevron`);
  if (targetCollapse && chevron) {
    chevron.classList.toggle('rotate-down');
    targetCollapse.classList.toggle('hide');
    return true;
    // this.props.actions.setListDisplayState({});
  }
  return false;
}
